<template>
    <div>
        <section id="page-title" class="page-title-parallax page-title-dark" style="background-image: url('/assets/images/about/parallax.jpg'); padding: 120px 0;" data-bottom-top="background-position:0px 300px;" data-top-bottom="background-position:0px -300px;">

			<div class="container clearfix">
				<h1>Job Openings</h1>
				<span>Join our Fabulous Team of Intelligent Individuals</span>
				<ol class="breadcrumb">
					<li class="breadcrumb-item"><router-link to="/">Home</router-link></li>
					<li class="breadcrumb-item active" aria-current="page">Jobs</li>
				</ol>
			</div>

		</section><!-- #page-title end -->
        <!-- Content
		============================================= -->
		<section id="content">
			<div class="content-wrap">
				<div class="container clearfix">
                    
					<div class="row col-mb-50">
                        <!-- sidebar -->
                        <div class="col-md-4">
                            <Sidebar/>
                        </div>
                        <!-- JD  -->
						<div class="col-md-8">
							<div>
                                <h3 style="width: 100%;padding: 0 0 0.75rem;border-bottom: 2px solid #1ABC9C;">Cold Caller
                                <router-link to="/careers/cold-caller/form" class="button button-3d m-0 button-primary" style="float:right">Apply Now</router-link></h3>
                                <h4>The Role</h4>
                                <p>We are looking for expert and rockstar virtual assistants who can do cold calling and can help with varied administrative and research-related tasks for our real estate project. The ideal candidate should be zealous about the real estate industry in general, a quick learner, and a great multi-tasker.</p>
                                <p>We know it can be tricky to apply for roles, wondering if the position is right for you and if you and your experience are suitable for the role. Many people won't apply for roles unless they feel that they tick every single box. At eFlex, we look for many different skills and abilities, and we're always looking for how new team members can add to eFlex and our culture. So if you don't think you quite meet all of the skills listed, we'd still love to hear from you!</p>

                                <div class="accordion accordion-bg">

                                    <div class="accordion-header">
                                        <div class="accordion-icon">
                                            <i class="accordion-closed icon-ok-circle"></i>
                                            <i class="accordion-open icon-remove-circle"></i>
                                        </div>
                                        <div class="accordion-title">
                                            What you'll be doing
                                        </div>
                                    </div>
                                    <div class="accordion-content">
                                        <ul class="iconlist iconlist-color mb-0">
                                            <li><i class="icon-ok"></i>Methodically qualify, build, and manage prospect lists</li>
                                            <li><i class="icon-ok"></i>Initial outreach to sales opportunities and funnel leads to US sales team</li>
                                            <li><i class="icon-ok"></i>Schedule calls/meetings to connect with prospects and the US sales team</li>
                                            <li><i class="icon-ok"></i>Experiment and iterate to create personalized customer communications</li>
                                            <li><i class="icon-ok"></i>Manage and maintain a pipeline of interested prospects</li>
                                            <li><i class="icon-ok"></i>Meet and exceed daily outbound email and social selling targets</li>
                                            <li><i class="icon-ok"></i>Highly motivated professional with excellent written/verbal communication skills</li>
                                            <li><i class="icon-ok"></i>Tracking all related activities and relevant information using Salesforce</li>
                                            <li><i class="icon-ok"></i>Grit. Lots of grit. You need to embrace challenges and be willing to work hard to achieve audacious goals.</li>
                                            <li><i class="icon-ok"></i>Conduct ongoing meetings via video conference with our US partner to communicate best practices, share updates, ask questions and strengthen our relationship with the partner.</li>
                                            <li><i class="icon-ok"></i>Work U.S. business hours and in-office</li>
                                        </ul>
                                    </div>

                                    <div class="accordion-header">
                                        <div class="accordion-icon">
                                            <i class="accordion-closed icon-ok-circle"></i>
                                            <i class="accordion-open icon-remove-circle"></i>
                                        </div>
                                        <div class="accordion-title">
                                            Minimum Qualifications
                                        </div>
                                    </div>
                                    <div class="accordion-content">
                                        <ul class="iconlist iconlist-color mb-0">
                                            <li><i class="icon-ok"></i>At least 2 years of experience as a customer sales representative in the BPO industry</li>
                                            <li><i class="icon-ok"></i>Familiar with sales tools such as Salesforce and LinkedIn Sales Navigator</li>
                                            <li><i class="icon-ok"></i>Experience qualifying inbound leads</li>
                                            <li><i class="icon-ok"></i>Excellent ability to communicate, extremely detail-oriented</li>
                                            <li><i class="icon-ok"></i>Good oral and written communication is vital - the individual must be able to speak clearly and persuasively in positive or negative situations</li>
                                            <li><i class="icon-ok"></i>Demonstrated group presentation skills, including the ability to effectively conduct meetings</li>
                                            <li><i class="icon-ok"></i>Organized mindset and an ability to manage time effectively</li>
                                            <li><i class="icon-ok"></i>Ability to think creatively and critically and thrive in a fast-paced, dynamic, and often ambiguous work environment</li>
                                            <li><i class="icon-ok"></i>Willingness to step up to greater responsibility, strong desire to contribute to overall goals</li>
                                        </ul>
                                    </div>
                                    
                                    <div class="accordion-header">
                                        <div class="accordion-icon">
                                            <i class="accordion-closed icon-ok-circle"></i>
                                            <i class="accordion-open icon-remove-circle"></i>
                                        </div>
                                        <div class="accordion-title">
                                            What you'll enjoy at eFlex
                                        </div>
                                    </div>
                                    <div class="accordion-content">
                                        <ul class="iconlist iconlist-color mb-0">
                                            <li><i class="icon-plus-sign"></i>We live our values as playmakers, obsessed with learning, care personally about our colleagues and partners, are radically open-minded, and take pride in everything we do.</li>
                                            <li><i class="icon-plus-sign"></i>We offer a Competitive Salary.</li>
                                            <li><i class="icon-plus-sign"></i>Work-from-home setup as we prepare to provide a safe environment for our employees. Our Future of Work plan is to move towards a hybrid work model (in-office & from home).</li>
                                            <li><i class="icon-plus-sign"></i>We offer comprehensive HMO and optical reimbursements.</li>
                                            <li><i class="icon-plus-sign"></i>Recharge and go on your next getaway or simply take time off for yourself through our flexible personal & sick days. We want our team to be happy and healthy :)</li>
                                            <li><i class="icon-plus-sign"></i>We support our employee's career growth and development by offering opportunities for promotion.</li>
                                        </ul>
                                    </div>

                                    <div class="accordion-header">
                                        <div class="accordion-title">
                                            About eFlexervices
                                        </div>
                                    </div>
                                    <div class="accordion-content">
                                        <p>eFlexervices is a 23-year-old BPO company providing exceptional quality and unwavering trust. Our partnership approach to the business allows us to match the right talent to every organization we support. We invest in people to optimize performance and maximize efficiency. We work hard to produce the highest possible results for our partners.</p>
                                        <p>Performance is the underlying foundation that drives eFlexervices. We deliver the metrics our partners expect through proper recruitment and heavy investment in the right people.</p>
                                        <p>Deep integration within our partners' organizations drives our team to take ownership of their work. This stakeholder mindset pushes higher performance, better quality, and longer retention.</p>
                                    </div>
                                    <router-link to="/careers/cold-caller/form" class="button button-3d m-0 button-primary" style="float:left">Apply Now</router-link>
                                </div>
                                <div class="divider divider-sm"></div>
                            </div>
						</div>
                        
                    </div>

				</div>
			</div>
		</section><!-- #content end -->

    </div>
</template>

<script>
import Sidebar from '@/components/CareerNav.vue'
export default {
    data() {
        return {
            
        }
    },
    components: {
        Sidebar
    },
    mounted() {
		window.scrollTo(0, 0)
	}
}
</script>